import { PiCaretRight } from 'react-icons/pi';
import Link from 'next/link';
import { HelpCircle, ListTodo, UsersRound } from 'lucide-react';
import { Button } from 'src/shared/ui/button';

import UnstyledLink from 'common/UnstyledLink';

export default function GetMoreWidget() {
  return (
    <div className="rounded-xl bg-ds-inverted-primary p-8 [&_p]:text-ds-text-inverted-primary [&_svg]:stroke-ds-fg-inverted-primary">
      <p className="text-2xl font-medium text-ds-text-inverted-primary">
        Get more from Cookie3
      </p>
      <p className="mt-1 text-sm text-ds-text-tertiary">
        Discover what&apos;s Cookie3&apos;s tools and features. Get started with
        our app docs, invite your team, and import your lists.
      </p>

      <div className=" mt-3 flex items-center justify-between gap-3 border-b border-ds-border-quarternary py-4  ">
        <div>
          <HelpCircle size={16} className="mb-1" />
          <p>App docs</p>
        </div>
        <Link href="https://docs.cookie3.com" target="_blank">
          <Button size={'s'} intent={'neutral'} className="flex items-center">
            Read
            <PiCaretRight className="-mt-0.5" />
          </Button>
        </Link>
      </div>

      <div className="border-ds-border-quarternary/30  flex items-center justify-between gap-3 border-b py-4">
        <div>
          <UsersRound size={16} className="mb-1 text-ds-fg-quarternary" />
          <p>Invite your team</p>
        </div>
        <UnstyledLink navigateWithinTeam href={`/settings/members`}>
          <Button size={'s'} intent={'neutral'}>
            Invite
            <PiCaretRight className="-mt-0.5" />
          </Button>
        </UnstyledLink>
      </div>

      <div className="border-ds-fg-quarternary/30  flex items-center justify-between gap-3 border-b py-4">
        <div>
          <ListTodo size={16} className="mb-1 text-ds-fg-quarternary" />
          <p>Import your lists</p>
        </div>
        <UnstyledLink navigateWithinTeam href="/csv">
          <Button size={'s'} intent={'neutral'}>
            Import
            <PiCaretRight className="-mt-0.5" />
          </Button>
        </UnstyledLink>
      </div>
    </div>
  );
}
