import { PiCaretRight } from 'react-icons/pi';
import Link from 'next/link';
import { Button } from 'src/shared/ui/button';

import UnstyledLink from 'common/UnstyledLink';

export default function AnalyzeOnChain() {
  return (
    <div className="relative flex min-h-[300px] flex-col overflow-hidden rounded-xl bg-ds-secondary p-8">
      <div className="flex flex-col gap-4">
        <p className="text-ds_l font-medium text-ds-text-primary">
          Segment your audience
        </p>
        <p className="w-1/2 text-ds_s text-ds-text-secondary">
          {
            'Create custom audiences based on their on-chain and off-chain activities.'
          }
        </p>

        <UnstyledLink href="/explorer" navigateWithinTeam>
          <Button size={'s'} intent={'neutral'}>
            Go to On-chain Explorer
            <PiCaretRight />
          </Button>
        </UnstyledLink>
      </div>

      <div className="absolute right-0 top-0 w-2/5">
        <Icons />
      </div>
    </div>
  );
}

function Icons() {
  return (
    <svg viewBox="0 0 265 259" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="71.6662"
        cy="186.666"
        r="49.3366"
        fill="white"
        fill-opacity="0.25"
        stroke="#E1E1E1"
        stroke-width="1.19444"
      />
      <circle
        cx="71.9256"
        cy="186.408"
        r="27.345"
        fill="white"
        stroke="#C6C6C6"
        stroke-width="1.19444"
      />
      <circle
        cx="71.6667"
        cy="186.667"
        r="71.2188"
        stroke="#EDEDED"
        stroke-width="0.895833"
      />
      <g clip-path="url(#clip0_17428_3439)">
        <mask
          id="mask0_17428_3439"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="59"
          y="177"
          width="25"
          height="19"
        >
          <rect
            x="59.7227"
            y="177.111"
            width="23.8889"
            height="17.9167"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_17428_3439)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M59.7227 177.111V195.028H83.6115V177.111H59.7227Z"
            fill="#F7FCFF"
          />
          <mask
            id="mask1_17428_3439"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="59"
            y="177"
            width="25"
            height="19"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M59.7227 177.111V195.028H83.6115V177.111H59.7227Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_17428_3439)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M59.7227 177.111V183.084H83.6115V177.111H59.7227Z"
              fill="#E31D1C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M59.7227 189.056V195.028H83.6115V189.056H59.7227Z"
              fill="#3D58DB"
            />
          </g>
        </g>
      </g>
      <rect
        x="60.3199"
        y="177.709"
        width="22.6944"
        height="16.7222"
        rx="1.19444"
        stroke="black"
        stroke-opacity="0.1"
        stroke-width="1.19444"
        style={{ mixBlendMode: 'multiply' }}
      />
      <circle
        cx="159.194"
        cy="70.1945"
        r="60.1556"
        fill="white"
        fill-opacity="0.5"
        stroke="#E1E1E1"
        stroke-width="1.19444"
      />
      <circle
        cx="159.51"
        cy="69.8797"
        r="33.3992"
        fill="white"
        stroke="#C6C6C6"
        stroke-width="1.19444"
      />
      <g clip-path="url(#clip1_17428_3439)">
        <path
          d="M171.856 57.4978L161.099 65.4576L163.099 60.7666L171.856 57.4978Z"
          fill="#E17726"
          stroke="#E17726"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M147.045 57.4978L157.707 65.5318L155.802 60.7666L147.045 57.4978Z"
          fill="#E27625"
          stroke="#E27625"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M167.985 75.9534L165.123 80.3259L171.252 82.0134L173.008 76.049L167.985 75.9534Z"
          fill="#E27625"
          stroke="#E27625"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M145.904 76.049L147.649 82.0134L153.768 80.3259L150.916 75.9534L145.904 76.049Z"
          fill="#E27625"
          stroke="#E27625"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M153.44 68.5694L151.737 71.1378L157.802 71.4137L157.6 64.8867L153.44 68.5694Z"
          fill="#E27625"
          stroke="#E27625"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M165.461 68.5685L161.237 64.8115L161.099 71.4128L167.164 71.1369L165.461 68.5685Z"
          fill="#E27625"
          stroke="#E27625"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M153.771 80.3254L157.441 78.553L154.281 76.0908L153.771 80.3254Z"
          fill="#E27625"
          stroke="#E27625"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M161.462 78.5557L165.122 80.3281L164.622 76.0935L161.462 78.5557Z"
          fill="#E27625"
          stroke="#E27625"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M165.122 80.3268L161.462 78.5544L161.76 80.9317L161.728 81.94L165.122 80.3268Z"
          fill="#D5BFB2"
          stroke="#D5BFB2"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M153.771 80.3268L157.175 81.94L157.154 80.9317L157.441 78.5544L153.771 80.3268Z"
          fill="#D5BFB2"
          stroke="#D5BFB2"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M157.234 74.5216L154.19 73.6301L156.34 72.6431L157.234 74.5216Z"
          fill="#233447"
          stroke="#233447"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M161.665 74.5216L162.559 72.6431L164.719 73.6301L161.665 74.5216Z"
          fill="#233447"
          stroke="#233447"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M153.771 80.3259L154.303 75.9534L150.919 76.049L153.771 80.3259Z"
          fill="#CC6228"
          stroke="#CC6228"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M164.598 75.9534L165.119 80.3259L167.981 76.049L164.598 75.9534Z"
          fill="#CC6228"
          stroke="#CC6228"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M167.164 71.1357L161.099 71.4117L161.663 74.5214L162.556 72.6428L164.716 73.6298L167.164 71.1357Z"
          fill="#CC6228"
          stroke="#CC6228"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M154.195 73.6298L156.345 72.6428L157.238 74.5214L157.802 71.4117L151.737 71.1357L154.195 73.6298Z"
          fill="#CC6228"
          stroke="#CC6228"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M151.737 71.1357L154.28 76.092L154.195 73.6298L151.737 71.1357Z"
          fill="#E27525"
          stroke="#E27525"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M164.718 73.6298L164.622 76.092L167.165 71.1357L164.718 73.6298Z"
          fill="#E27525"
          stroke="#E27525"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M157.799 71.4114L157.235 74.5211L157.948 78.1931L158.108 73.3536L157.799 71.4114Z"
          fill="#E27525"
          stroke="#E27525"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M161.103 71.4114L160.805 73.343L160.954 78.1931L161.667 74.5211L161.103 71.4114Z"
          fill="#E27525"
          stroke="#E27525"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M161.668 74.5214L160.955 78.1935L161.466 78.5543L164.626 76.0921L164.722 73.6299L161.668 74.5214Z"
          fill="#F5841F"
          stroke="#F5841F"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M154.19 73.6299L154.276 76.0921L157.436 78.5543L157.946 78.1935L157.234 74.5214L154.19 73.6299Z"
          fill="#F5841F"
          stroke="#F5841F"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M161.73 81.9399L161.761 80.9316L161.485 80.6981H157.42L157.154 80.9316L157.175 81.9399L153.771 80.3267L154.962 81.303L157.378 82.9693H161.517L163.943 81.303L165.124 80.3267L161.73 81.9399Z"
          fill="#C0AC9D"
          stroke="#C0AC9D"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M161.464 78.5539L160.953 78.1931H157.952L157.442 78.5539L157.154 80.9313L157.42 80.6978H161.485L161.762 80.9313L161.464 78.5539Z"
          fill="#161616"
          stroke="#161616"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M172.315 65.9776L173.22 61.5838L171.858 57.4978L161.462 65.1923L165.463 68.5671L171.113 70.2122L172.358 68.7582L171.815 68.3655L172.677 67.5802L172.017 67.0707L172.879 66.4127L172.315 65.9776Z"
          fill="#763E1A"
          stroke="#763E1A"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M145.68 61.5838L146.595 65.9776L146.01 66.4127L146.882 67.0707L146.222 67.5802L147.084 68.3655L146.542 68.7582L147.786 70.2122L153.437 68.5671L157.437 65.1923L147.042 57.4978L145.68 61.5838Z"
          fill="#763E1A"
          stroke="#763E1A"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M171.113 70.2138L165.463 68.5688L167.165 71.1372L164.622 76.0934L167.984 76.051H173.007L171.113 70.2138Z"
          fill="#F5841F"
          stroke="#F5841F"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M153.438 68.5688L147.788 70.2138L145.904 76.051H150.916L154.278 76.0934L151.735 71.1372L153.438 68.5688Z"
          fill="#F5841F"
          stroke="#F5841F"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M161.103 71.4119L161.464 65.1927L163.103 60.7671H155.804L157.442 65.1927L157.804 71.4119L157.942 73.3647L157.953 78.1937H160.954L160.964 73.3647L161.103 71.4119Z"
          fill="#F5841F"
          stroke="#F5841F"
          stroke-width="0.204762"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <circle
        cx="159.194"
        cy="70.1944"
        r="86.7465"
        stroke="#EDEDED"
        stroke-width="0.895833"
      />
      <circle
        cx="232.28"
        cy="173.28"
        r="39.7659"
        fill="white"
        fill-opacity="0.25"
        stroke="#E1E1E1"
        stroke-width="1.19444"
      />
      <circle
        cx="232.42"
        cy="173.42"
        r="21.9894"
        fill="white"
        stroke="#C6C6C6"
        stroke-width="1.19444"
      />
      <rect
        x="223.012"
        y="164.012"
        width="19.0315"
        height="19.0315"
        fill="white"
      />
      <rect
        x="223.012"
        y="164.012"
        width="19.0315"
        height="19.0315"
        stroke="#F7F7F7"
        stroke-width="0.0796296"
      />
      <path
        d="M232.528 183.084C237.806 183.084 242.084 178.806 242.084 173.528C242.084 168.251 237.806 163.973 232.528 163.973C227.251 163.973 222.973 168.251 222.973 173.528C222.973 178.806 227.251 183.084 232.528 183.084Z"
        fill="#00EC97"
      />
      <path
        d="M235.292 169.41L233.378 172.254C233.246 172.448 233.5 172.682 233.684 172.519L235.567 170.878C235.618 170.837 235.689 170.868 235.689 170.939V176.066C235.689 176.137 235.598 176.168 235.557 176.117L229.855 169.288C229.672 169.064 229.408 168.941 229.112 168.941H228.909C228.379 168.941 227.941 169.38 227.941 169.92V177.136C227.941 177.676 228.379 178.115 228.919 178.115C229.255 178.115 229.57 177.941 229.754 177.646L231.668 174.802C231.8 174.608 231.546 174.374 231.362 174.537L229.479 176.168C229.428 176.209 229.357 176.178 229.357 176.107V170.99C229.357 170.919 229.448 170.888 229.489 170.939L235.19 177.768C235.374 177.992 235.649 178.115 235.934 178.115H236.137C236.677 178.115 237.115 177.676 237.115 177.136V169.92C237.115 169.38 236.677 168.941 236.137 168.941C235.791 168.941 235.476 169.115 235.292 169.41Z"
        fill="#030000"
      />
      <circle
        cx="231.931"
        cy="172.931"
        r="57.4826"
        stroke="#EDEDED"
        stroke-width="0.895833"
      />
      <defs>
        <clipPath id="clip0_17428_3439">
          <rect
            x="59.7227"
            y="177.111"
            width="23.8889"
            height="17.9167"
            rx="1.79167"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1_17428_3439">
          <rect
            width="28.6667"
            height="28.6667"
            fill="white"
            transform="translate(144.861 55.8611)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
