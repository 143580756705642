type Props = {
  className?: string;
  isVertical?: boolean;
};

export default function FluidConnector({ className, isVertical }: Props) {
  if (isVertical) {
    return (
      <div className={className}>
        <svg
          width="192"
          height="32"
          viewBox="0 0 192 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_17337_9802)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.9909 -992.045C-2.57434 -992.045 -21.6777 -972.942 -21.6777 -949.377L-21.6779 -42.6686C-21.6779 -19.1034 -2.57446 2.50511e-06 20.9908 5.59532e-06L52.9906 9.79159e-06C61.8287 1.09506e-05 68.9934 7.16468 68.9934 16.0028C68.9934 24.8408 61.8287 32.0055 52.9906 32.0055L20.9908 32.0055C-2.57439 32.0055 -21.6778 51.1089 -21.6778 74.6742L-21.6778 224.014C-21.6778 247.579 -2.5744 266.683 20.9908 266.683L170.331 266.683C193.896 266.683 213 247.579 213 224.014L213 74.6741C213 51.1089 193.896 32.0055 170.331 32.0055L138.332 32.0055C129.494 32.0055 122.329 24.8408 122.329 16.0028C122.329 7.16469 129.494 1.98238e-05 138.332 2.09827e-05L170.331 2.51789e-05C193.896 2.82691e-05 213 -19.1034 213 -42.6686L213 -949.377C213 -972.942 193.896 -992.045 170.331 -992.045L20.9909 -992.045Z"
              fill="var(--secondary-ds)"
            />
          </g>
          <defs>
            <clipPath id="clip0_17337_9802">
              <rect
                width="32"
                height="192"
                fill="var(--secondary-ds)"
                transform="translate(192) rotate(90)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
  return (
    <div className={className}>
      <svg
        width="32"
        height="192"
        viewBox="0 0 32 192"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_17337_9834)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M-992.045 171.009C-992.045 194.574 -972.942 213.678 -949.376 213.678L-42.6682 213.678C-19.1029 213.678 0.000489951 194.574 0.000492011 171.009L0.000494809 139.009C0.000495582 130.171 7.16517 123.007 16.0032 123.007C24.8413 123.007 32.006 130.171 32.006 139.009L32.006 171.009C32.006 194.574 51.1094 213.678 74.6747 213.678L224.015 213.678C247.58 213.678 266.683 194.574 266.683 171.009L266.683 21.6691C266.683 -1.89618 247.58 -20.9996 224.015 -20.9996L74.6746 -20.9996C51.1094 -20.9996 32.006 -1.8962 32.006 21.669L32.006 53.6681C32.006 62.5061 24.8413 69.6708 16.0032 69.6708C7.16517 69.6708 0.000501497 62.5061 0.00050227 53.6681L0.000505067 21.669C0.000507127 -1.89628 -19.1029 -20.9997 -42.6681 -20.9997L-949.376 -20.9998C-972.942 -20.9998 -992.045 -1.89641 -992.045 21.6688L-992.045 171.009Z"
            fill="var(--secondary-ds)"
          />
        </g>
        <defs>
          <clipPath id="clip0_17337_9834">
            <rect width="32" height="192" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
